import { laptop, collaborativeBot, stakingETH } from '@images';

export const dexTradingCards = [
  {
    id: 1,
    img: laptop,
    title: 'Triggers',
    value: '12,000',
  },
  {
    id: 2,
    img: collaborativeBot,
    title: 'Users',
    value: '96,000',
  },
  {
    id: 3,
    img: stakingETH,
    title: 'Connected to app',
    value: '$53.6m',
  },
];
