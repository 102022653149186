import React from 'react';

import LinkBtn from '@components/link-btn';
import { dexTradingCards } from './StatsSection.content';

const StatsSection = () => {
  return (
    <div className="bg-white pt-6 sm:pt-8 lg:pt-6">
      <div className="mt-6 bg-white sm:pb-16 lg:pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="font-bold text-green-400 leading-tight text-6xl">
              Join thousand of other users <br />{' '}
              <span className="font-bold text-coolGray-800 ">
                {' '}
                exploring DEX trading automation{' '}
              </span>
            </h2>
            <p className="mt-3 text-coolGray-500 font-medium text-xl sm:mt-4">
              BOTSWAP enables crypto traders to engage in advanced DeFi
              strategies with a drag-and-drop interface. Manage liquidity,
              automate trades and improve your returns
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 h-1/2 bg-white" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <dl className="rounded-lg bg-white sm:grid sm:grid-cols-3">
              {dexTradingCards.map((card) => (
                <div key={card.id} className="flex flex-col p-6 text-center">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-coolGray-500">
                    {card.title}
                  </dt>
                  <dd className="order-1 text-5xl pb-6 font-extrabold text-indigo-400">
                    <img
                      className="w-full lg:h-full"
                      src={card.img}
                      alt={card.title}
                    />
                  </dd>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-400">
                    {card.value}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="flex justify-center mt-9 mb-16">
              <LinkBtn
                className="mb-1"
                href="https://app.botswap.fi/"
                text="Configure DEX bots for free"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
