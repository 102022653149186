import React from 'react';

import HeroSection from '@components/hero-section';

import DexPlatformSection from '@components/dex-platform-section';
import HowItWorksSection from '@components/how-it-works-section';
import StatsSection from '@components/stats-section';
import TryItSection from '@components/try-it-section';
import SEO from '@components/seo';
import { botHero } from '@images';
import TryBotswap from '../../components/try-botswap';

const Home = () => {
  return (
    <main>
      <SEO
        description="BotSwap enables crypto traders to engage in advanced DeFi strategies with a simple interface. Configure trading bots to do the work for you.Manage liquidity, automate trades and improve your returns"
        title="BotSwap: Automated DeFi trading for decentralised exchanges"
      />
      <HeroSection
        title={
          <>
            <span className="text-indigo-500 text-7x1 font-semibold">BotSwap</span>
            <span className="text-green-300 text-7x1 font-semibold">: Automate your DEX trading</span>
          </>
        }
        subTitle={
          <>
            Automate your DeFi and DEX trading strategy using simple to setup
            and maintain crypto trading tools
          </>
        }
        img={botHero}
      />
      <StatsSection />
      <DexPlatformSection />
      <HowItWorksSection />
      <TryBotswap/>
    </main>
  );
};

export default Home;
