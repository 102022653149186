import React from 'react';

import { processingBot } from '@images';
import { dexPlatforms } from './dexPlatform.content';

const DexPlatformSection = () => {
  return (
    <div className="bg-coolGray-900 overflow-hidden lg:py-12">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative my-12 sm:my-16 lg:my-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="font-bold text-white text-5xl leading-tight mb-10">
                Integrated with all the main{' '}
                <span className="text-green-300">DEX Platforms</span>
              </h3>
              <div className="mt-4 grid gap-x-1 gap-y-8 grid-cols-2">
                {dexPlatforms.map((item) => (
                  <div
                    key={item.name}
                    className="p-7 rounded bg-white text-center align-middle"
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      class={item.class || ''}
                    />
                    {item.text && (
                      <span class="text-xl font-semibold blue-gray-900">
                        {item.text}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <img
                className="relative w-full"
                src={processingBot}
                alt="Processing Bot"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DexPlatformSection;
