import React from 'react';

import LinkBtn from '@components/link-btn';
import { freeBotswap } from '../../images';

const TryBotswap = () => {
  return (
    <div className="pb-16 bg-white overflow-hidden lg:pb-16">
      <div className="max-w-5xl mx-auto">
        <div className="rounded-lg bg-coolGray-900 flex juify-between">
          <div className="w-3/4 pt-16 pl-16">
            <h3 className="font-bold font-inter text-white text-3xl">
              TRY BOTSWAP FOR <span className="text-green-300">FREE</span>
            </h3>
            <div className="mt-6 mb-10 font-normal text-xl text-base text-white">
              For all of April BotSwap is now free for all <br />
              users. Join 70,000 others enjoying DEX trading automation built on
              Fetch.ai
            </div>
            <LinkBtn
              href="https://app.botswap.fi/"
              text="Get started for free"
            />
          </div>
          <div className="relative right-16 content-end">
            <img src={freeBotswap} alt="Free Botswap" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryBotswap;
