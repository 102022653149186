import { wallet, mouseArrow, settingsGear } from '@images';

export const walkthoughCards = [
  {
    id: 1,
    img: wallet,
    title: 'Connect Wallet',
  },
  {
    id: 2,
    img: mouseArrow,
    title: 'Configure Triggers',
  },
  {
    id: 3,
    img: settingsGear,
    title: 'Manage and Optimize',
  },
];
