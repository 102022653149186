import React from 'react';

import Layout from '@components/layout';

import Home from './home';

const Index = () => {
  return (
    <Layout headerClass="absolute z-50">
      {/* Nav Section */}
      {/* See /src/components/primary-navigation.js */}
      {/* End Nav Section */}
      <Home />
      {/* Footer Section */}
      {/* See /src/components/footer.js */}
      {/* End Footer Section */}
    </Layout>
  );
};

export default Index;
