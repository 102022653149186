import { uniSwap, panecakeSwap, oneInch, sushiSwap } from '@images';

export const dexPlatforms = [
  {
    id: 1,
    name: 'Uniswap',
    img: uniSwap,
  },
  {
    id: 2,
    name: 'PancakeSwap',
    img: panecakeSwap,
  },
  {
    id: 3,
    name: 'SushiSwap',
    img: sushiSwap,
    text: 'Coming Soon',
    class: 'opacity-20',
  },
  {
    id: 4,
    name: '1inch',
    img: oneInch,
    text: 'Coming Soon',
    class: 'opacity-20',
  },
];
