import React from 'react';

import LinkBtn from '@components/link-btn';
import Card from '@components/card';
import { walkthoughCards } from './howItWorks.content';

const HowItWorksSection = () => {
  return (
    <div className="bg-white pt-16 sm:pt-16">
      <div className="pb-16 bg-white lg:pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="font-bold text-gray-800 leading-tight text-5xl">
              How it works
            </h2>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <dl className="rounded-lg bg-white gap-x-14 sm:grid sm:grid-cols-3">
              {walkthoughCards.map((card) => (
                <Card
                  key={card.id}
                  img={card.img}
                  title={card.title}
                  rounded={true}
                />
              ))}
            </dl>
            <div className="flex justify-center mt-12 pb-16">
              <LinkBtn
                className="mt-0 px-24 py-3"
                href="/how-it-works"
                text="Take a walkthrough"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
